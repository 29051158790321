var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "OtherRules" },
    [
      _vm.form.specialMonth || _vm.typeNo.slice(0, 1) === "B"
        ? _c(
            "a-form-model-item",
            { staticStyle: { display: "flex" }, attrs: { label: "发放月份" } },
            [
              _vm._v(" " + _vm._s(_vm.form.name) + " "),
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.monthChange },
                  model: {
                    value: _vm.form.isSpecialMonth,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isSpecialMonth", $$v)
                    },
                    expression: "form.isSpecialMonth",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("全部月份")]),
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("部分月份")]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.form.isSpecialMonth
                    ? _c(
                        "a-select",
                        {
                          staticClass: "line-input",
                          attrs: {
                            mode: "tags",
                            placeholder: "请选择月份，多选",
                          },
                          model: {
                            value: _vm.form.specialMonthList,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "specialMonthList", $$v)
                            },
                            expression: "form.specialMonthList",
                          },
                        },
                        _vm._l(_vm.monthList, function (month) {
                          return _c(
                            "a-select-option",
                            { key: month.value, attrs: { value: month.value } },
                            [_vm._v(" " + _vm._s(month.label) + " ")]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.form.isAttendScale || _vm.typeNo.slice(0, 1) === "B"
        ? _c(
            "a-form-model-item",
            { attrs: { label: "折算方式" } },
            [
              _c("DictionariesInput", {
                staticClass: "line-input",
                attrs: {
                  parameter: "attendscalemode",
                  placeholder: "请选择折算方式",
                },
                model: {
                  value: _vm.form.attendScaleMode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "attendScaleMode", $$v)
                  },
                  expression: "form.attendScaleMode",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.form.deductRule &&
      _vm.form.deductRuleList &&
      _vm.form.deductRuleList.length > 0
        ? _c(
            "a-form-model-item",
            { attrs: { label: "扣发规则" } },
            [
              _c("p", [
                _vm._v("设置后，满足任意条件的员工将不享受对应的津贴补贴"),
              ]),
              _vm.form.deductRuleList.includes("staff_status")
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "员工状态" } },
                    [
                      _c("a-checkbox-group", {
                        attrs: { options: _vm.staffStatusList },
                        model: {
                          value: _vm.form.deductRulePara.staff_status,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.deductRulePara,
                              "staff_status",
                              $$v
                            )
                          },
                          expression: "form.deductRulePara.staff_status",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.deductRuleList.includes("inoutmonth")
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "入离职状态" } },
                    [
                      _c("a-checkbox-group", {
                        attrs: { options: _vm.inoutmonthList },
                        model: {
                          value: _vm.form.deductRulePara.inoutmonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.deductRulePara, "inoutmonth", $$v)
                          },
                          expression: "form.deductRulePara.inoutmonth",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.deductRuleList.includes("engage_mode")
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "聘用形式" } },
                    [
                      _c("a-checkbox-group", {
                        attrs: { options: _vm.engageModeList },
                        model: {
                          value: _vm.form.deductRulePara.engage_mode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.deductRulePara,
                              "engage_mode",
                              $$v
                            )
                          },
                          expression: "form.deductRulePara.engage_mode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.deductRuleList.includes("absencetype")
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "考勤异常" } },
                    [
                      _c("a-checkbox-group", {
                        attrs: { options: _vm.absenceTypeList },
                        model: {
                          value: _vm.form.deductRulePara.absencetype,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.deductRulePara,
                              "absencetype",
                              $$v
                            )
                          },
                          expression: "form.deductRulePara.absencetype",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.deductRuleList.includes("vacation_type")
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "请假类型" } },
                    [
                      _c("a-checkbox-group", {
                        attrs: { options: _vm.vacationTypeList },
                        model: {
                          value: _vm.form.deductRulePara.vacation_type,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.deductRulePara,
                              "vacation_type",
                              $$v
                            )
                          },
                          expression: "form.deductRulePara.vacation_type",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }